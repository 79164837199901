.login-container{
    height: 100vh;
    background: url('../../images/bg.png');
    background-size: cover;
    filter: blur(8px);
  -webkit-filter: blur(5px);
}
.login-form{
    position: absolute;
    height: 70%;
    width: 20%;
    right: 35%;
    top: 15%;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 50px;
}
.login-form>button{
    margin-top: 20px !important;
}
.login-form>.data-input{
    border: none !important;
}
.login-form>.data-input>div>input{
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 5px;
}
.login-form>img{
    align-self: center;
}