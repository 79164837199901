.side-bar{
    position: absolute;
    width: 200px;
    height: 70%;
    top: 175px;
    left: 30px;
    z-index: 99;
}
.side-bar-content{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--sidebar-bg);
    border-radius: 5px;
    overflow: hidden;
}
.side-bar-title{
    padding: 10px;
    text-align: left;
    background-color: #212121;
    color: var(--main-text-color);
    border-bottom: 3px solid #07C6E0;
    font-weight: 600;
    font-size: 18px;
}
.side-bar-container{
    padding: 20px;
}
.side-bar-container>ul, .side-bar-container>ul>li>ul{
    padding: 0;
    list-style: none;
}
.side-bar-container>ul>div{
    border-color: #6CD400 !important;
}
.side-bar-container>ul>div, .last_op{
    margin-bottom: 10px;
}
.side-bar-container>ul>div>p{
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Inter', sans-serif  !important;
}
.side-bar-container>ul>li>div>span, .side-bar-container>ul>li>ul>li>div>span{
    font-size: 12px;
    color: #D8D8D8;
}
.side-bar-container>ul>li>div>p, .side-bar-container>ul>li>ul>li>div>p{
    font-family: 'Inter', sans-serif  !important;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.side-bar-container>ul>div, .side-bar-container>ul>li>div, .side-bar-container>ul>li>ul>li>div{
    padding: 10px;
    background-color: #161618;
    color: #fff;
    border: 1px solid #7D7D7D;
    border-radius: 5px;
}
.side-bar-container>ul>li, .side-bar-container>ul>li>ul>li{
    margin-left: 25px;
    margin-bottom: 5px;
}
.side-bar-container>ul>li>div.first_op::after, .side-bar-container>ul>li>ul>li>div.first_op::after{
    height: 40px !important;
    transform: translate(-28px, -60px);
}
.side-bar-container>ul>li>div::after, .side-bar-container>ul>li>ul>li>div::after{
    content: "";
    position: absolute;
    width: 15px;
    height: 70px;
    background-color: transparent;
    transform: translate(-28px, -90px);
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    border-bottom-left-radius: 10px;
}