.header-section{
    z-index: 9999 !important;
    position: absolute;
    background-color: #FF5B15;
    width: calc(100% - 60px);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}
.header-section>div{
    display: flex;
    align-items: center;
}
.header-section>div>p{
    margin-right: 20px;
    font-family: 'Inter', sans-serif  !important;
}
.header-section>div>p>span{
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px
}
.logout-button{
    margin-bottom: 20px;
    color: var(--main-color);
    border-color: var(--main-color) !important;
}
.header-options{
    position: absolute;
    top: 70px;
    height: 80px;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: #292c2ebd;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.data-input>label, .data-input>p{
    color: var(--main-text-color);
}
.data-input>div>input{
    background-color: var(--dark-color);
    color: var(--main-text-color);
}
.main-button{
    background-color: var(--main-color) !important;
    margin: 5px 0 !important;
    height: 40px;
}
.time-slider-container{
    width: 500px
}
.time-slider-container>div{
    margin: 0;
    font-size: 12px;
    width: 100% ;
    display: flex;
    justify-content: space-between;
    color: #B9B9B9;
    font-family: 'Inter', sans-serif  !important;
}
.time-slider-container>span{
    font-size: 14px;
    padding-bottom: 10px !important;
    font-family: 'Inter', sans-serif  !important;
}
.time-slider{
    color: #FFB314 !important;
    width: 100% !important;
}
.time-slider>span.MuiSlider-thumb{
    border: 4px solid #FFB314 !important;
    background-color: #fff !important;
}
.time-slider>span.MuiSlider-thumb>.MuiSlider-valueLabelOpen{
    font-size: 12px !important; 
    padding: 0.15rem 0.45rem !important;
    line-height: 1 !important;
}
.chip{
    color: #fff !important;
}
.selected_trip_range{
    color: #fff !important;
    background-color: #ffb5148b !important;
    border-color: #FFB314 !important;
}
.map-center-selector>fieldset{
    border-color: #fff !important;
}
.map-center-selector>svg{
    fill: #fff;
}