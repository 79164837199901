@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
    font-family: 'Raleway', sans-serif !important;
    color: var(--main-text-color);
    box-sizing: 0;
    margin: 0;
}
.mapboxgl-control-container{
    display: none !important;
}
@import url("./layouts/login.css");
@import url("./layouts/HomePage.css");
@import url("./components/map.css");
@import url("./components/sidebar.css");
@import url("./components/header.css");
@import url("./variables_dark.css")